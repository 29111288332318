import info from '../build.json'
import { version } from '../package.json'

export default {
	methods: {
		getBuild () {
			return 'Version: ' + version + ' - ' + info.Build + ' - ' + info.Time.substring(0, 10)
		}
	}
}

//
//
//
//
//
//
//
//
//
//

import topNav from '../components/top-nav'
import footerComp from '../components/footerComp'
export default {
  data () {
    return {
    }
  },
  name: 'defaultLayout',
  components: {
   topNav,
   footerComp
  },
}

import { render, staticRenderFns } from "./default.vue?vue&type=template&id=62c05588&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/.pnpm/vue-loader@15.9.7_0aadd186e864c83cab1284bae568d6ae/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TopNav: require('/var/tomcat/.jenkins/workspace/januxmedia/januxmedia-2.x-site/components/top-nav.vue').default,FooterComp: require('/var/tomcat/.jenkins/workspace/januxmedia/januxmedia-2.x-site/components/footerComp.vue').default})

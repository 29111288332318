/* eslint-disable import/first */
// import BootstrapVue from 'bootstrap-vue'
// Vue.use(BootstrapVue)

import Vue from 'vue'
import { BNavbar } from 'bootstrap-vue'
import { NavbarPlugin } from 'bootstrap-vue'
import { BButton } from 'bootstrap-vue'
import { BForm } from 'bootstrap-vue'
import { FormPlugin } from 'bootstrap-vue'
import { BFormGroup } from 'bootstrap-vue'
import { FormGroupPlugin } from 'bootstrap-vue'
import { BFormInput } from 'bootstrap-vue'
import { FormInputPlugin } from 'bootstrap-vue'
import { BFormRadioGroup } from 'bootstrap-vue'
import { FormRadioPlugin } from 'bootstrap-vue'
import { BFormTextarea } from 'bootstrap-vue'
import { FormTextareaPlugin } from 'bootstrap-vue'
import { BAlert } from 'bootstrap-vue'
import { AlertPlugin } from 'bootstrap-vue'

Vue.component('b-navbar', BNavbar)
Vue.component('b-button', BButton)
Vue.use(NavbarPlugin)
Vue.component('b-form', BForm)
Vue.component('b-form-group', BFormGroup)
Vue.use(FormPlugin)
Vue.component('b-form-input', BFormInput)
Vue.use(FormGroupPlugin)
Vue.component('b-form-radio-group', BFormRadioGroup)
Vue.use(FormInputPlugin)
Vue.component('b-form-textarea', BFormTextarea)
Vue.use(FormRadioPlugin)
Vue.component('b-alert', BAlert)
Vue.use(FormTextareaPlugin)
Vue.use(AlertPlugin)

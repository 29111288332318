//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import readBuild from '../util/readBuild'

export default {
  name: 'footerComp',
  data () {
	return {
		currentDate: '',
		build: ''
	}
  },
  created () {
	this.currentYear()
  },
  mixins: [readBuild],
  mounted () {
		this.build = this.getBuild()
  },
  methods: {
	currentYear() {
		let d = new Date()
  		let n = d.getFullYear()
		this.currentDate = n
	}
  }
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
	data () {
		return {
			isSticky: false
		}
	},
	mounted () {
		document.addEventListener('scroll', this.onScroll)
		// console.log('onScroll ', window.scrollY)
	},
	beforeDestroy () {
		document.removeEventListener('scroll', this.onScroll)
	},
	methods: {
		onScroll () {
			let topPosition = '10'
			// console.log('onScroll ', window.scrollY)
			if (window.scrollY > topPosition) {
				this.isSticky = true
			} else {
				this.isSticky = false
			}
		}
	}
}
